<template>
  <el-dialog v-model="ImgDisplayVisible" title="灾后图片" width="50%">
    <div>
      <el-select v-model="option" style="margin-top: 0; margin-bottom: 20px">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
      <el-carousel :height="bannerHeight + 'px'">
        <el-carousel-item v-for="item in imgsUrl" :key="item">
          <div style="">
            <img :src="item.url" class="bannerimg" />
            <!--          <el-image :src="item.url" class="bannerimg" lazy></el-image>-->
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "ImgDisplay",
  data() {
    return {
      loading: true,
      bannerHeight: 200,
      ImgDisplayVisible: false,
      imgsUrl1: [
        { url: "https://file.zouran.top/imgs/hangpai/100_0001_0170.jpg" },
        { url: "https://file.zouran.top/imgs/hangpai/100_0001_0167.jpg" },
        { url: "https://file.zouran.top/imgs/hangpai/100_0001_0164.jpg" },
        { url: "https://file.zouran.top/imgs/hangpai/100_0001_0157.jpg" },
      ],
      imgsUrl2: [
        { url: "https://file.zouran.top/imgs/shipai/20210620173127.jpg" },
        { url: "https://file.zouran.top/imgs/shipai/20210620173215.jpg" },
        { url: "https://file.zouran.top/imgs/shipai/20210620173240.jpg" },
        { url: "https://file.zouran.top/imgs/shipai/IMG_1432.jpg" },
        { url: "https://file.zouran.top/imgs/shipai/IMG_1442.jpg" },
      ],
      imgsUrl: [
        { url: "https://file.zouran.top/imgs/hangpai/100_0001_0170.jpg" },
        { url: "https://file.zouran.top/imgs/hangpai/100_0001_0167.jpg" },
        { url: "https://file.zouran.top/imgs/hangpai/100_0001_0164.jpg" },
        { url: "https://file.zouran.top/imgs/hangpai/100_0001_0157.jpg" },
      ],
      option: "灾后航拍图",
      options: [
        {
          label: "灾后航拍图",
          value: "hangpai",
        },
        {
          label: "灾后实拍图",
          value: "shipai",
        },
      ],
    };
  },
  watch: {
    option(val) {
      if (val === "hangpai") this.imgsUrl = this.imgsUrl1;
      if (val === "shipai") this.imgsUrl = this.imgsUrl2;
    },
  },
  mounted() {
    this.setSize();
    window.addEventListener(
      "resize",
      () => {
        this.setSize();
      },
      false
    );
  },
  methods: {
    setSize() {
      this.bannerHeight = document.body.clientWidth / 4;
    },
  },
};
</script>

<style scoped>
.bannerimg {
  width: 100%;
  height: inherit;
}
</style>
